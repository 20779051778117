<template>
    <div class="content-box">
        <el-card class="my-20">
            <div class="d-flex-center">
                <div class="d-inline-flex-center is-link" @click="$nav.back()">
                    <i class="icon icon-back my-8 mr-8" />
                    <span class="color-666">{{ $t('back') }}</span>
                </div>
                <div class="font-16 ml-20 pl-20 mr-auto font-bold color-666 border-left">News Details</div>
                <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="fatchData()">{{ $t('refresh') }}</el-button>
            </div>
        </el-card>
        <el-card class="" v-loading="loading">
            <h1 v-text="info.title" />
            <p v-text="info.create_time" />
            <el-divider />
            <div v-loading="loading" class="des-box" v-html="info.content" />
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // active: 0
            navMenu: [],
            loading: true,
            info: {
                title: '',
                content: '<br/><br/><br/><br/>'
            }
        }
    },
    created: function () {
        this.fatchData()
    },
    methods: {
        fatchData: function (){
            this.loading = true
            this.$request.get('notice/item/id/' + this.$route.params.id).then(({ data }) => {
                this.info = data
                this.loading = false
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.des-box{
    line-height: 22px;
}
</style>